import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CountdownTimer = ({ secondsLeft, setSecondsLeft = 0, score }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft - 1);
      } else {
        setTimeout(() => {
          clearInterval(timer);
          // localStorage.setItem("score", score);
          navigate("/game-score", {
            state: {
              score: score,
            },
          });
        }, 500);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [secondsLeft]);

  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;

  return (
    <h5 className="text">
      {minutes < 10 ? `0${minutes}` : minutes}:
      {seconds < 10 ? `0${seconds}` : seconds}
    </h5>
  );
};

export default CountdownTimer;
