import { useContext, useEffect, useState } from "react";

// Plugins

// Adapters
import { Box, Button, CircularProgress, styled } from "@mui/material";
import { AuthContext } from "../../providers/AuthProvider";

import { useSelector } from "@/hooks/redux";
import { authSelector } from "@/redux/features/auth/selectors";
import { useDispatch } from "react-redux";
import "./ScorePage.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { toast } from "react-toastify";
import { sendGameResult } from "@/apis/nft-pet";

//Services
const InviteButton = styled(Button)(() => ({
  color: "#000",

  "&:hover": {
    backgroundColor: "#EEE67B",
    opacity: "0.7",
    color: "#000",
  },
}));
const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  height: "100vh",
  // backgroundColor: "#F7E8F6",
  background:
    "linear-gradient(0deg,rgba(0, 0, 0, 1), rgba(27, 48, 57, 0.5), rgba(27, 48, 57, 1))",
  backgroundImage: `url(assets/v2/bg.png)` /* Set your background image URL */,
  backgroundSize: "cover" /* Make the background image cover the entire div */,
  backgroundPosition: "center" /* Center the background image */,
  backgroundRepeat: "no-repeat",

  "& .dragon_logo_container": {
    position: "relative",
    width: "218px",
    height: "218px",
    background:
      "linear-gradient(180deg, rgba(2, 177, 170, 0.30)0%, rgba(238, 230, 123, 0.30)100%)",
    borderRadius: "360px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .dragon_logo_body": {
    background:
      "linear-gradient(180deg, rgba(2, 177, 170, 0.40)0%, rgba(238, 230, 123, 0.40)100%)",
    borderRadius: "360px",
    width: "85%",
    height: "85%",
  },
  "& .dragon_logo": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  "& .attempt": {
    ml: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EEE67B",
    padding: "2px 8px",
    borderRadius: "40px",
    fontSize: 12,
    fontWeight: "bold",
    gap: "6px",
    color: "#1B3039",
  },
}));

export const WalletAmountBox = styled(Button)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "left !important",
  background: "#fff",
  boxShadow: " 1px 1px 0px 0px #0D1615",
  borderRadius: "10px",
  border: "1px solid #0D1615",
  padding: "10px",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "15px",
  letterSpacing: "0.07px",
  alignItems: "center",
  textTransform: "none",
}));
export const WalletAmountContinue = styled(Button)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between !important",
  background: "#fff",
  boxShadow: " 1px 1px 0px 0px #0D1615",
  borderRadius: "10px",
  border: "1px solid #0D1615",
  padding: "10px",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "15px",
  letterSpacing: "0.07px",
  alignItems: "center",
  textTransform: "none",
}));

const Logo = styled("img")(() => ({
  // marginTop: "30%",
  // width: "188px",
  // height: "200px",
  width: "200px",
  marginBottom: "50px",
  imageRendering: "pixelated",
}));
const LogoModal = styled("img")(() => ({
  width: "98px",
}));

const LogoSocial = styled("img")(() => ({
  width: "24px",
  marginRight: "20px",
}));

const Content = styled(Box)(() => ({
  padding: "12px 50px 12px 50px",
  // marginTop: "-50px",
}));

const ContentTitle = styled(Box)(() => ({
  textAlign: "center",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "0.07px",
  // paddingBottom: "18px",
  fontSize: 40,
  fontWeight: "bold",
  textTransform: "uppercase",
  color: "#FFFFFF",
  WebkitTextStroke: "1.5px black",
}));

const ContentBody = styled(Box)(() => ({
  paddingTop: "18px",
  fontSize: 14,
  fontWeight: "400",
  color: "#FDFF7A",
  textAlign: "center",
}));

const ContentDescription = styled(Box)(() => ({
  textAlign: "center",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",
  letterSpacing: "0.07px",
  color: "white",
  // marginTop: 25,
  justifyContent: "center",
}));

const ScoreBackgroundContainer = styled(Box)(() => ({
  background: "linear-gradient(to right, #06c9c3 70%, #02B1AA, #EEE67B)",
  width: "130px",
  position: "absolute",
  bottom: "-23px",
  left: "50%",
  transform: "translate(-50%)",
  borderRadius: "12px",
}));

const ScoreContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: "#1B3039",
  margin: "2px",
  gap: "2px",
  padding: "4px 16px",
  borderRadius: "12px",

  "& .title": {
    textAlign: "cent,er",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.07px",
    // paddingBottom: "18px",
    fontSize: 20,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#FFFFFF",
    WebkitTextStroke: "1.5px black",
  },

  "& .subtitle": {
    textAlign: "center",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.07px",
    // paddingBottom: "18px",
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#7D97A2",
  },
}));

const LoginButton = styled(Button)(() => ({
  marginTop: "48px",
  width: "150px",
}));

const ScorePage: React.FC = () => {
  const dispatch = useDispatch();
  const authState = useSelector(authSelector.selectorDomain);
  const { web3auth, login, loading, loginUserWithTon } =
    useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  // get userId
  let score = location.state.score;

  // const score = localStorage.getItem("score") || "0";
  const currentTickets = localStorage.getItem("tickets") || "1";
  const remainingTickets = parseInt(currentTickets) - 1;
  const petImage = localStorage.getItem('petImage');

  useEffect(() => {
    (async () => {
      try {
        const payload = parseInt(score);
        await sendGameResult(payload);
      } catch (e: any) {
        toast.error(e.message || "Error sending game result");
      }
    })();
  }, []);
  
  return (
    <Container>
      <Content>
        <ContentBody>INSANE SKILLS</ContentBody>
        <ContentTitle>
          You're a <br />
          Master
        </ContentTitle>
      </Content>

      <CountdownCircleTimer
        isPlaying
        duration={6}
        onComplete={() => {
          navigate("/my-pet");
        }}
        colors="url(#your-unique-id)"
        size={235}
        strokeWidth={8}
        trailColor={"#1B3039"}
      >
        {({ remainingTime }) => (
          <div className="dragon_logo_container">
            <div className="dragon_logo_body">
              <Logo
                className="dragon_logo"
                src={petImage || "assets/images2/login-dragon-v2.png"}
                alt="Logo"
              />
              <ScoreBackgroundContainer>
                <ScoreContainer>
                  <div className="subtitle">REWARD</div>
                  <div className="title">{score}RP</div>
                </ScoreContainer>
              </ScoreBackgroundContainer>
            </div>
          </div>
        )}
      </CountdownCircleTimer>
      <div style={{ width: "100%", padding: "0px 12px" }}>
        <Button
          disabled={remainingTickets <= 0}
          onClick={() => {
            localStorage.setItem("tickets", remainingTickets.toString());
            navigate("/game-fruit");
          }}
          fullWidth
          size="large"
          sx={{
            mb: "15px",
            mt: "24px",
            gap: "6px",
            border: "none",
            "&.Mui-disabled": {
              backgroundColor: "#C4C4C4 !important",
              color: "#000000",
            },
          }}
        >
          Play Game{" "}
          <div className="attempt">
            <img
              src={"assets/v2/ic_ticket.png"}
              alt="level"
              style={{ width: "18px" }}
            />
            {remainingTickets} TICK
          </div>
        </Button>

        <InviteButton
          onClick={() => {
            navigate("/tasks");
          }}
          fullWidth
          size="large"
          sx={{ mb: "15px", backgroundColor: "#EEE67B" }}
        >
          Earn Tick
        </InviteButton>
        <svg style={{ height: "0px" }}>
          <defs>
            <linearGradient id="your-unique-id" x1="1" y1="0" x2="0" y2="0">
              <stop offset="5%" stopColor="#EEE67B" />
              <stop offset="95%" stopColor="#02B1AA" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      {/* <LoginButton variant="contained" onClick={() => loginUserWithTon(true)}>
        Start
      </LoginButton> */}
      {/* )} */}
    </Container>
  );
};

export default ScorePage;
