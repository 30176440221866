import { useContext, useEffect, useState } from "react";

// Plugins

// Adapters
import { Box, Button, CircularProgress, styled } from "@mui/material";
import { AuthContext } from "../../providers/AuthProvider";

import { useSelector } from "@/hooks/redux";
import { logoutAsync } from "@/redux/features/auth/saga";
import { authSelector } from "@/redux/features/auth/selectors";
import authSlice from "@/redux/features/auth/slice";
import { useDispatch } from "react-redux";
import "./LoadingPage.css";
import { useNavigate } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useLaunchParams } from "@tma.js/sdk-react";
import { redeemFriendCode } from "@/apis/nft-pet";

//Services

const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  // backgroundColor: "#F7E8F6",
  background:
    "linear-gradient(0deg,rgba(0, 0, 0, 1), rgba(27, 48, 57, 0.5), rgba(27, 48, 57, 1))",

  "& .dragon_logo_container": {
    position: "relative",
    width: "218px",
    height: "218px",
    background:
      "linear-gradient(180deg, rgba(2, 177, 170, 0.20)0%, rgba(238, 230, 123, 0.20)100%)",
    borderRadius: "360px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .dragon_logo_body": {
    background:
      "linear-gradient(180deg, rgba(2, 177, 170, 0.40)0%, rgba(238, 230, 123, 0.40)100%)",
    borderRadius: "360px",
    width: "85%",
    height: "85%",
  },
  "& .dragon_logo": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const WalletAmountBox = styled(Button)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "left !important",
  background: "#fff",
  boxShadow: " 1px 1px 0px 0px #0D1615",
  borderRadius: "10px",
  border: "1px solid #0D1615",
  padding: "10px",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "15px",
  letterSpacing: "0.07px",
  alignItems: "center",
  textTransform: "none",
}));
export const WalletAmountContinue = styled(Button)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between !important",
  background: "#fff",
  boxShadow: " 1px 1px 0px 0px #0D1615",
  borderRadius: "10px",
  border: "1px solid #0D1615",
  padding: "10px",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "15px",
  letterSpacing: "0.07px",
  alignItems: "center",
  textTransform: "none",
}));

const Logo = styled("img")(() => ({
  // marginTop: "30%",
  // width: "188px",
  // height: "200px",
  width: "200px",
  marginBottom: "50px",
  imageRendering: "pixelated",
}));

const Content = styled(Box)(() => ({
  padding: "12px 50px 12px 50px",
  // marginTop: "-50px",
}));

const ContentTitle = styled(Box)(() => ({
  textAlign: "center",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "0.07px",
  // paddingBottom: "18px",
  fontSize: 40,
  fontWeight: "bold",
  textTransform: "uppercase",
  color: "#FFFFFF",
  WebkitTextStroke: "1.5px black",
}));

const ContentBody = styled(Box)(() => ({
  paddingTop: "18px",
  fontSize: 14,
  fontWeight: "400",
  color: "#FDFF7A",
  textAlign: "center",
}));

const LoadingPage: React.FC = () => {
  //Get start param
  const launchParams = useLaunchParams();

  const dispatch = useDispatch();
  const authState = useSelector(authSelector.selectorDomain);
  const { web3auth, login, loading, loginUserWithTon } =
    useContext(AuthContext);

  const logout = async () => {
    if (!web3auth) {
      return;
    }
    if (!web3auth.connected) {
      dispatch(authSlice.actions.setReferral(""));
      dispatch(logoutAsync());
      window.token = "";
      localStorage.removeItem("skipScreenInviteCode");
      return;
    }
    await web3auth.logout();
    dispatch(authSlice.actions.setReferral(""));
    dispatch(logoutAsync());
    window.token = "";
    localStorage.removeItem("skipScreenInviteCode");
  };

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        await loginUserWithTon(true);
      } catch (error) {
        console.log(error);
      }

      if (launchParams?.startParam && launchParams?.startParam?.length > 0) {
        try {
          await redeemFriendCode(launchParams.startParam);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, []);

  return (
    <Container>
      <svg style={{ height: "0px" }}>
        <defs>
          <linearGradient id="your-unique-id" x1="1" y1="0" x2="0" y2="0">
            <stop offset="5%" stopColor="#EEE67B" />
            <stop offset="95%" stopColor="#02B1AA" />
          </linearGradient>
        </defs>
      </svg>
      <CountdownCircleTimer
        isPlaying
        duration={3}
        onComplete={() => {
          navigate("/my-pet");
        }}
        colors="url(#your-unique-id)"
        size={235}
        strokeWidth={8}
        trailColor={"#1B3039"}
      >
        {({ remainingTime }) => (
          <div className="dragon_logo_container">
            <div className="dragon_logo_body">
              <Logo
                className="dragon_logo"
                src="assets/images2/login-dragon-v2.png"
                alt="Logo"
              />
            </div>
          </div>
        )}
      </CountdownCircleTimer>

      <Content>
        <ContentTitle>RooLz</ContentTitle>
        <ContentTitle>petS</ContentTitle>
        <ContentBody>Loading...</ContentBody>
      </Content>
      {/* <LoginButton variant="contained" onClick={() => loginUserWithTon(true)}>
        Start
      </LoginButton> */}
      {/* )} */}
    </Container>
  );
};

export default LoadingPage;
