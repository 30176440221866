// src/components/Game.js
import React, { useState, useEffect, useRef } from "react";
import Basket from "./Basket";
import Fruit from "./Fruit";
import CountdownTimer from "./CountdownTime";
import { Box, styled } from "@mui/material";
import Explosion from "./Explosion";

// import appleImg from "../../../public/assets/v2/fruit/fruit_001"
// import peachImg from "../../../public/assets/v2/fruit/fruit_002"
// import bananaImg from "../../../public/assets/v2/fruit/fruit_003"

const StaticContainer = styled(Box)(() => ({
  background: "#1B3039",
  width: "120px",
  height: "40px",
  borderRadius: "16px",
  display: "flex",
  gap: "6px",
  alignItems: "center",
  justifyContent: "center",
  userSelect: "none",

  "&.right": {
    position: "absolute",
    top: "16px",
    right: "16px",
  },

  "&.left": {
    position: "absolute",
    top: "16px",
    left: "16px",
  },

  "& .text": {
    fontsize: "16px",
    textEdge: "Cap",
    color: "white",
    fontWeight: "bold",
  },
}));

const scoreCheck = {
  position: "absolute",
  bottom: "200px",
  right: `30%`,
  width: "100px",
  userSelect: "none",
  display: "flex",
  gape: "4px",
  // alignItem: "center",
};

const plusText = {
  color: "#EEE67B",
  fontFamily: "SF Pro Rounded",
  fontSize: "32px",
  fontWeight: "700",
};

const minusText = {
  color: "#EF1A1A",
  fontFamily: "SF Pro Rounded",
  fontSize: "32px",
  fontWeight: "700",
};

const Game = () => {
  const audioRefCoin = useRef(null);
  const audioRefBomb = useRef(null);
  const [basketPosition, setBasketPosition] = useState(200);
  const [fruits, setFruits] = useState([]);
  const [score, setScore] = useState(0);
  const [explosion, setExplosion] = useState(null); // State to handle explosion animation
  const gameAreaRef = useRef(null);
  const [secondsLeft, setSecondsLeft] = useState(20);
  const [plus, setPlus] = useState(false);
  const [minus, setMinus] = useState(false);
  const levelData = JSON.parse(localStorage.getItem("level"));
  const smallFruit = 25;
  const bigFruit = 35;
  const fruitTypes = [
    {
      type: "apple",
      color: "red",
      size: bigFruit,
      src: "assets/v2/fruit/fruit_001.png",
    },
    {
      type: "apple",
      color: "red",
      size: smallFruit,
      src: "assets/v2/fruit/fruit_001.png",
    },
    {
      type: "peach",
      color: "orange",
      size: bigFruit,
      src: "assets/v2/fruit/fruit_002.png",
    },
    {
      type: "peach",
      color: "orange",
      size: smallFruit,
      src: "assets/v2/fruit/fruit_002.png",
    },
    {
      type: "banana",
      color: "yellow",
      size: bigFruit,
      src: "assets/v2/fruit/fruit_003.png",
    },
    {
      type: "banana",
      color: "yellow",
      size: smallFruit,
      src: "assets/v2/fruit/fruit_003.png",
    },
    {
      type: "bomb",
      color: "black",
      size: bigFruit,
      src: "assets/v2/fruit/fruit_004.png",
    },
    {
      type: "bomb",
      color: "black",
      size: smallFruit,
      src: "assets/v2/fruit/fruit_004.png",
    },
    // Add more fruit types as needed
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const randomFruit =
        fruitTypes[Math.floor(Math.random() * fruitTypes.length)];
      setFruits((prevFruits) => [
        ...prevFruits,
        {
          id: Date.now(),
          position: Math.random() * (window.innerWidth - randomFruit.size),
          top: 0,
          type: randomFruit.type,
          color: randomFruit.color,
          size: randomFruit.size,
          src: randomFruit.src,
        },
      ]);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const speed = 3 + 0.05 * parseInt(levelData || 0);
      setFruits((prevFruits) =>
        prevFruits.map((fruit) => ({
          ...fruit,
          top: fruit.top + speed,
        }))
      );
    }, 20);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFruits((prevFruits) =>
        prevFruits.filter((fruit) => {
          if (
            fruit.top >= window.innerHeight - 220 &&
            fruit.top <= window.innerHeight - 120
          ) {
            if (
              fruit.position >= basketPosition - 35 &&
              fruit.position <= basketPosition + 100
            ) {
              if (fruit.type !== "bomb") {
                try {
                  if (audioRefCoin?.current) {
                    audioRefCoin.current.play();
                  }
                } catch (e) {}
                if (secondsLeft > 1) {
                  setScore((prevScore) => prevScore + 10);
                  setPlus(true);
                  setTimeout(() => {
                    setPlus(false);
                  }, 300);
                }
              } else {
                try {
                  if (audioRefBomb?.current) {
                    audioRefBomb.current.play();
                  }
                } catch (e) {}
                setExplosion({
                  position: fruit.position,
                  top: fruit.top,
                  id: Date.now(),
                });
                // setTimeout(()=>{
                //   setExplosion(null);
                // })
                if (score >= 10) {
                  if (secondsLeft > 1) {
                    setScore((prevScore) => prevScore - 10);
                    setMinus(true);
                    setTimeout(() => {
                      setMinus(false);
                    }, 300);
                  }
                }
              }
              return false;
            }
            // return false;
          }
          if (fruit.top >= window.innerHeight - 140) {
            return false;
          }
          return true;
        })
      );
    }, 20);

    return () => clearInterval(interval);
  }, [basketPosition]);

  const handleMouseMove = (e) => {
    const newBasketPosition = e.clientX - gameAreaRef?.current?.offsetLeft - 50;
    setBasketPosition(
      Math.min(Math.max(newBasketPosition, -50), window.innerWidth - 50)
    );
  };

  const handleTouchMove = (e) => {
    e.preventDefault(); // Prevent default behavior to stop the reset issue
    const touch = e.touches[0];
    const newBasketPosition =
      touch.clientX - gameAreaRef?.current?.offsetLeft - 50;
    setBasketPosition(
      Math.min(Math.max(newBasketPosition, -50), window.innerWidth - 50)
    );
  };

  useEffect(() => {
    const gameArea = gameAreaRef.current;

    gameArea.addEventListener("mousemove", handleMouseMove);
    gameArea.addEventListener("touchmove", handleTouchMove, { passive: false }); // Add { passive: false } to prevent default behavior

    return () => {
      gameArea.removeEventListener("mousemove", handleMouseMove);
      gameArea.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  // const handleMouseMove = (e) => {
  //   e.preventDefault(); // Prevent the default touch behavior

  //   const newBasketPosition = e.clientX - gameAreaRef.current.offsetLeft - 50;
  //   setBasketPosition(
  //     Math.min(Math.max(newBasketPosition, 0), window.innerWidth - 100)
  //   );
  // };

  // const handleTouchMove = (e) => {
  //   e.preventDefault(); // Prevent the default touch behavior
  //   const touch = e.touches[0];
  //   const newBasketPosition =
  //     touch.clientX - gameAreaRef.current.offsetLeft - 50;
  //   setBasketPosition(
  //     Math.min(Math.max(newBasketPosition, 0), window.innerWidth - 100)
  //   );
  // };

  // useEffect(() => {
  //   const gameArea = gameAreaRef.current;

  //   window.addEventListener("mousemove", handleMouseMove);
  //   window.addEventListener("touchmove", handleTouchMove);

  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //     window.removeEventListener("touchmove", handleTouchMove);
  //   };
  // }, []);
  const handleExplosionEnd = () => {
    setExplosion(null);
  };

  return (
    <div
      ref={gameAreaRef}
      style={{
        position: "relative",
        height: "100vh",
        overflow: "hidden",
        background:
          "linear-gradient(0deg,rgba(0, 0, 0, 1), rgba(27, 48, 57, 0.5), rgba(27, 48, 57, 1))",
        backgroundImage: `url(assets/v2/bg.png)` /* Set your background image URL */,
        backgroundSize:
          "cover" /* Make the background image cover the entire div */,
        backgroundPosition: "center" /* Center the background image */,
        backgroundRepeat: "no-repeat",
        userSelect: "none",
      }}
    >
      <StaticContainer className={"left"}>
        <img
          style={{ height: "24px", width: "24px", userSelect: "none" }}
          className="dragon_logo"
          src={"assets/v2/score/ic_clock.png"}
          alt="clock"
        />
        <CountdownTimer
          secondsLeft={secondsLeft}
          setSecondsLeft={setSecondsLeft}
          score={score}
        />
      </StaticContainer>
      <StaticContainer className={"right"}>
        <img
          style={{ height: "24px", width: "24px", userSelect: "none" }}
          className="dragon_logo"
          src={"assets/v2/score/ic_coin.png"}
          alt="coin"
        />
        <h5 className="text">{score} RP</h5>
      </StaticContainer>
      <Basket position={basketPosition} />
      {fruits.map((fruit) => (
        <Fruit
          key={fruit.id}
          position={fruit.position}
          top={fruit.top}
          color={fruit.color}
          size={fruit.size}
          source={fruit.src}
        />
      ))}
      <audio ref={audioRefCoin} src={"sounds/coin.wav"} preload="auto" />
      <audio ref={audioRefBomb} src={"sounds/explosion.wav"} preload="auto" />

      {explosion && (
        <Explosion
          key={explosion.id} // Unique key to force re-render
          position={explosion.position}
          top={explosion.top}
          onAnimationEnd={handleExplosionEnd}
          setExplosion={setExplosion}
          // Pass the handler
        />
      )}

      <div style={scoreCheck}>
        {plus && <div style={plusText}>+10</div>}
        {minus && <div style={minusText}>-10</div>}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "-26px",
          left: "0px",
          right: "0px",
          height: "148px",
          backgroundImage: `url(assets/v2/ground.png)` /* Set your background image URL */,
          backgroundSize: "cover",
        }}
        className="dragon_logo"
      />
      {/* Render explosion */}
    </div>
  );
};

export default Game;
