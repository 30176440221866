// src/components/Fruit.js
import React from "react";

const Fruit = ({ position, top, color, size, source }) => {
  const fruitStyle = {
    position: "absolute",
    top: `${top}px`,
    left: `${position}px`,
    width: `${size}px`,
    height: `${size}px`,
    // backgroundColor: color,
    borderRadius: "50%",
    transition: "top 0.02s linear",
    userSelect: "none",
  };

  return (
    <div style={fruitStyle}>
      <img
        style={{ height: "100%", width: "100%", userSelect: "none"}}
        className="dragon_logo"
        src={source}
        alt="Logo"
      />
    </div>
  );
};

export default Fruit;

// style={{
//   position: "relative",
//   height: "100vh",
//   overflow: "hidden",
//   background:
//     "linear-gradient(0deg,rgba(0, 0, 0, 1), rgba(27, 48, 57, 0.5), rgba(27, 48, 57, 1))",
//   backgroundImage: `url(assets/v2/bg.png)` /* Set your background image URL */,
//   backgroundSize:
//     "cover" /* Make the background image cover the entire div */,
//   backgroundPosition: "center" /* Center the background image */,
//   backgroundRepeat: "no-repeat",
// }}
