import { ENVS } from "@/configs/Configs.env";
import { accountSelectors } from "@/redux/features/account/selectors";
import { ISymbol } from "@/types/auth";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useTokens() {
  const tokens = useSelector(accountSelectors.selectTokens);
  const [gemToken, setGemToken] = useState({
    balance: "0",
    decimals: 1,
    symbol: ISymbol.MGEM,
  });
  useEffect(() => {
    const mb = tokens[ENVS.REACT_APP_MP_TOKEN];
    if (mb) {
      setGemToken({
        balance: mb.amount,
        decimals: mb.decimals,
        symbol: ISymbol.MGEM,
      });
    }
  }, [tokens]);
  return { gemToken };
}
