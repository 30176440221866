import * as authApi from "@/apis/auth";
import * as nftPetApi from "@/apis/nft-pet";
import { ReactComponent as LevelUpgradeIcon } from "@/assets/svg/level-upgrade.svg";
import Loader from "@/components/Loader";
import PetLifeTime from "@/components/PetLifeTime";
import ContentBorderWrapper from "@/components/presentation/ContentBorderWrapper";
import { MainContent } from "@/components/presentation/MainContent";
import { useDispatch, useSelector } from "@/hooks/redux";
import useAudios from "@/hooks/useAudios";
import useBurnNft from "@/hooks/useBurnNft";
import useBuyItem from "@/hooks/useBuyItem";
import useMintNft from "@/hooks/useMintNft";
import useTokens from "@/hooks/useTokens";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import { AuthContext } from "@/providers/AuthProvider";
import { appSelectors } from "@/redux/features/app/selectors";
import appSlice from "@/redux/features/app/slice";
import { logoutAsync } from "@/redux/features/auth/saga";
import { authSelector } from "@/redux/features/auth/selectors";
import authSlice from "@/redux/features/auth/slice";
import { petSelectors } from "@/redux/features/pet/selectors";
import petSlice from "@/redux/features/pet/slice";
import { GlobalConfig, GlobalFeedItem } from "@/types/app";
import { Pet, PetState, PetStatusLive, PetWithState } from "@/types/pet";
import useAnalyticsEventTracker from "@/utils/useAnalyticsEventTracker";
import * as amplitude from "@amplitude/analytics-browser";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Grid, IconButton, styled } from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as RoOlzIcon } from "@/assets/svg/roOlz.svg";

import {
  AnimatedMainPet,
  AnimatedMainPetBackground,
  AnimatedMainPetBushes1,
  AnimatedMainPetBushes2,
  AnimatedMainPetStreet,
  MainPetBox,
  MainPetWrapper,
  AvatarIconButton,
} from "./PetLevelDetail.styled";
import { getShopItems } from "@/apis/shop";
import zIndex from "@mui/material/styles/zIndex";

const DescriptionBox = styled(Box)(() => ({
  position: "relative",
  padding: "58px 24px 16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "58vh",
}));

const ScoreBackgroundContainer = styled(Box)(() => ({
  background: "linear-gradient(to right, #06c9c3 70%, #02B1AA, #EEE67B)",
  width: "170px",
  height: "58px",
  borderRadius: "12px",
}));

const ScoreContainer = styled(Box)(() => ({
  display: "flex",
  height: "94%",
  alignItems: "center",
  justifyContent: "space-around",
  gap: "6px",
  background: "#1B3039",
  margin: "2px",
  padding: "4px 18px",
  borderRadius: "12px",

  "& .figure_container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "2px",
  },

  "& .title": {
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.07px",
    // paddingBottom: "18px",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#FFFFFF",
    WebkitTextStroke: "1px black",
  },

  "& .subtitle": {
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.07px",
    // paddingBottom: "18px",
    fontSize: 10,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#7D97A2",
  },
}));

const PetImage = styled("img")(() => ({
  position: "absolute",
  bottom: "58px !important",
  left: "29% !important",
  zIndex: 100,
  height: "100px !important",
  width: "auto !important",
  border: "none",
  maxWidth: "180px",
}));

const ContentTitle = styled(Box)(() => ({
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "0.07px",
  // paddingBottom: "18px",
  fontSize: 36,
  fontWeight: "bold",
  textTransform: "uppercase",
  color: "#FFFFFF",
  WebkitTextStroke: "1.5px black",
}));

const ListBox = styled(Box)(() => ({
  paddingTop: "8px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "6%",
  overflowX: "scroll",
}));

const containerStyle = {
  paddingTop: "8px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "6%",
  overflowX: "scroll",
};
const AvatarBorder = styled(Box)(() => ({
  minWidth: "50px",
  height: "50px",
  position: "relative",
  borderRadius: "8px",
  background: "linear-gradient(to right, #06c9c3 70%, #02B1AA, #EEE67B)",

  "& .yes_tick": {
    position: "absolute",
    top: "-8px",
    right: "-8px",
    zIndex: 2,
  },
}));
const DisablePet = styled(Box)(() => ({
  minWidth: "50px",
  height: "50px",
  position: "relative",
  background: "#203641",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  border: "1px solid #7D97A2",
}));

const PetLevelDetail: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { onBurn } = useBurnNft();
  const { web3auth: web3AuthGlobal, logout: logoutWallet } =
    useContext(AuthContext);
  const [showModalEditPetName, setShowModalEditPetName] = useState(false);
  const [openLevelModal, setOpenLevelModal] = useState(false);
  const [feedItem, setFeedItem] = useState<GlobalFeedItem | null>(null);
  const [isFeeding, setIsFeeding] = useState<boolean>(false);
  const levelRef = useRef<HTMLSpanElement>(null);
  const levelTextRef = useRef<HTMLSpanElement>(null);
  const [hideLevelTextWidth, setHideLevelTextWidth] = useState<number>(0);
  const [hideLevelText, setHideLevelText] = useState<boolean>(true);
  const [isLevelLoading, setIsLevelLoading] = useState<boolean>(false);
  const authState = useSelector(authSelector.selectorDomain);
  const { onMintNft, isMinting } = useMintNft();
  const [openSideBar, setOpenSideBar] = useState(false);
  const activePet = useSelector(petSelectors.selectActivePet);
  const windowDimensions = useWindowDimensions();

  const pets = useSelector(petSelectors.selectPets);
  const [firstLoading, setFirstLoading] = useState(pets.length > 0);
  // const feedItems = useSelector(appSelectors.selectFeedItems);
  const [mainPetState, setMainPetState] = useState<PetState>(
    activePet && activePet.petState ? activePet.petState : PetState.IDLE
  );
  const [newNamePet, setNewNamePet] = useState(
    activePet ? activePet.pet?.name : ""
  );

  //V2 new state
  const [currentPet, setCurrentPet] = useState<any>({});
  const [levelList, setLevelList] = useState<any>([]);
  const [currentSelect, setCurrentSelect] = useState(-1);
  // const [petDetailList, setPetDetailList] = useState<any>([]);

  const ContentText = styled(Box)(() => ({
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.07px",
    // paddingBottom: "18px",
    fontSize: 12,
    color: "#7D97A2",
    overflowY: "scroll",
    height: windowDimensions.height * 0.58 - 45 - 68 - 21 - 84,
  }));

  const ContentBody = styled(Box)(() => ({
    fontSize: 14,
    fontWeight: "400",
    color: "#FDFF7A",
  }));
  const foodRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const mainPetWrapperRef = useRef<HTMLDivElement>(null);

  const nextLevel = activePet?.pet?.petLevel ? activePet?.pet?.petLevel + 1 : 2;
  const mainPetEquip = activePet?.petEquip || undefined;
  const [feeds, setFeeds] = useState<any>([]);
  const initFreeShop = async () => {
    const data = await getShopItems(true);
    // console.log('free food data: ', data);
    setFeeds(data?.items);
  };
  const gaEventTracker = useAnalyticsEventTracker("My Pet");

  const fetchActivePet = async (nftId: any) => {
    // Get pet active
    const pet: PetWithState = await nftPetApi.getMyPetFeed(nftId);
    dispatch(petSlice.actions.activePet({ pet }));
    pet.petState && setMainPetState(pet.petState);
    setNewNamePet(pet.pet?.name);
    // pet.petState && setMainPetState(pet.petState);
  };

  const fetchPets = async () => {
    console.log("Fetch pet function call");
    try {
      // Get list my pet
      // const pets: Pet[] = await nftPetApi.getMyPets();
      const profile: any = await authApi.getProfile();
      const { pets } = profile;
      if (pets.length > 0) {
        dispatch(petSlice.actions.setPets({ pets }));

        // Get pet active
        fetchActivePet(activePet ? activePet.pet?.nftId : pets[0].nftId);
        // fetchActivePet(activePet ? activePet : pets[0]);
      }
    } catch (e: any) {
      console.log("Not found NFTs.");
    } finally {
      setTimeout(() => {
        setFirstLoading(true);
      }, 1000);
    }
  };
  useEffect(() => {
    console.log("authState?.isJustMint: ", authState?.isJustMint);
    if (authState?.isJustMint && pets.length < 2) {
      dispatch(authSlice.actions.setIsJustMint(false));
      navigate("/guide");
    } else {
      dispatch(authSlice.actions.setIsJustMint(false));
    }
  }, [authState?.isJustMint]);

  useEffect(() => {
    // if (!firstLoading || pets.length === 0) {
    //   console.log("fetch init data");
    //   fetchPets();
    // }
    fetchPets();
    initFreeShop();
  }, [firstLoading, feedItem]);
  //v2: fetch INFO
  useEffect(() => {
    (async () => {
      try {
        const data = await nftPetApi.getAllLevelInfo();
        if (data) {
          setLevelList(
            data.petLevels?.filter((item: any) => item.ageLevel > 0)
          );
          setCurrentPet({...data.petLevel, petScore: data.pet.petScore});
          setCurrentSelect(data.petLevel.ageLevel - 1);
        }
      } catch (e: any) {
        toast.error(e?.message);
      }
    })();
  }, []);
  const container = document.querySelector("#scrollContainer");
  useEffect(() => {
    if (currentPet && currentPet.ageLevel >= 5) {
      if (container) {
        container.scrollTo({
          left: container.scrollWidth,
          behavior: "smooth", // Enable smooth scrolling
        });
      }
    }
  }, [currentPet, levelList, container]);

  useEffect(() => {
    const timer = setInterval(async () => {
      if (activePet) {
        await fetchActivePet(activePet?.pet?.nftId);
      }
    }, 8500);

    return () => {
      clearInterval(timer);
    };
  }, [activePet]);

  useEffect(() => {
    gaEventTracker("view_main", "view_main");
    amplitude.track("view_main");
  }, []);

  useEffect(() => {
    if (activePet && hideLevelTextWidth > 0) {
      if (
        (activePet.pet.petScore || 0) > calculatePointsToNextLevel(nextLevel)
      ) {
        setHideLevelText(false);
        setTimeout(() => {
          setHideLevelText(true);
        }, 5000);
      }
    }
  }, [activePet, hideLevelTextWidth]);

  useEffect(() => {
    if (
      levelRef.current !== null &&
      levelTextRef.current !== null &&
      hideLevelTextWidth === 0
    ) {
      const levelWidth = levelRef.current.clientWidth;
      const levelTextWidth = levelTextRef.current.clientWidth;
      setHideLevelTextWidth(levelWidth - levelTextWidth - 8);
    }
  }, [levelRef.current, levelTextRef.current]);

  if (!firstLoading) {
    return <Loader />;
  }

  function calculatePointsToNextLevel(targetLevel: any) {
    const BASE_POINT = 500;
    let totalScore = 0;

    for (let level = 1; level < targetLevel; level++) {
      const nextLevelScore =
        BASE_POINT + Math.pow(BASE_POINT, Math.log10(level));
      totalScore += nextLevelScore;
    }

    return Math.round((totalScore * 10) / 10);
  }

  const handleBack = () => {
    navigate("/my-pet");
  };

  return (
    <MainContent sx={{ padding: activePet ? 0 : "15px 20px" }}>
      <IconButton
        onClick={handleBack}
        size="small"
        edge="start"
        sx={{
          mr: 2,
          background: "#1B3039",
          position: "absolute",
          top: "16px",
          left: "16px",
          zIndex: 2,
          border: "none",
        }}
      >
        <ArrowLeftIcon style={{ fill: "#ffffff" }} fontSize="small" />
      </IconButton>
      <Grid
        container
        spacing={2}
        // sx={{ mb: "20px" }}
        className="container-my-pet"
      >
        <Grid item xs={12}>
          {activePet ? (
            <MainPetWrapper
              className="testing"
              ref={mainPetWrapperRef}
              screenHeight={windowDimensions.height}
              foodRef={foodRef}
              menuRef={menuRef}
            >
              <MainPetBox>
                {/* Pet Animation */}
                {/* {activePet && (
                  <AnimatedMainPet
                    hasPoo={activePet.poo > 0}
                    state={PetState.IDLE}
                    equip={mainPetEquip}
                    screenWidth={
                      windowDimensions.width < 500
                        ? windowDimensions.width
                        : 500
                    }
                  />
                )} */}
                {levelList?.length > 0 && (
                  <PetImage
                    alt="Pet Image"
                    // src="assets/images2/login-dragon-v2.png"
                    src={
                        levelList?.[currentSelect]?.petImageUrl
                        || currentPet?.petImageUrl
                    }
                  />
                )}
                <AnimatedMainPetBackground wrapperRef={mainPetWrapperRef} />
                <AnimatedMainPetBushes1
                  screenWidth={
                    windowDimensions.width < 500 ? windowDimensions.width : 500
                  }
                />
                <AnimatedMainPetBushes2
                  screenWidth={
                    windowDimensions.width < 500 ? windowDimensions.width : 500
                  }
                />
                <AnimatedMainPetStreet />
                {/* <AnimatedMainPetEatingBattery
                  hasPoo={activePet.poo > 0}
                  state={mainPetState}
                /> */}
              </MainPetBox>
            </MainPetWrapper>
          ) : (
            <div>No Pet Available! Please contact admin!</div>
          )}
        </Grid>
      </Grid>
      <DescriptionBox>
        <div
          style={{
            position: "absolute",
            zIndex: "100",
            width: "100%",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "8px",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-around",
            top: "-12px",
            background: "transparent",
          }}
        >
          <ScoreBackgroundContainer>
            <ScoreContainer>
              <img
                className="icon_figure"
                src={"assets/v2/score/ic_coin.png"}
                alt="yes"
                style={{ width: "28px" }}
              />
              <div className="figure_container">
                <div className="subtitle">Coins Prize</div>
                <div className="title">
                  {/* {levelList?.[currentSelect]?.pointLevel?.toLocaleString() ||
                    0}{" "} */}
                  {parseInt(currentPet?.petScore || 0)} RP
                </div>
              </div>
            </ScoreContainer>
          </ScoreBackgroundContainer>
          <ScoreBackgroundContainer>
            <ScoreContainer>
              <RoOlzIcon width={28} />
              <div className="figure_container">
                <div className="subtitle">
                  LVL {currentPet?.ageLevel + 1}, Evolution
                </div>
                <div className="title">
                  {parseInt(currentPet?.pointLevel || 0)} RP
                </div>
              </div>
            </ScoreContainer>
          </ScoreBackgroundContainer>
        </div>
        <div className="content_container">
          <ContentBody>Level {currentSelect + 1}</ContentBody>
          <ContentTitle>
            {currentSelect === -1
              ? "No Skill"
              : levelList?.[currentSelect]?.name}
          </ContentTitle>
          <ContentText>
            {currentSelect === -1
              ? "No skill description, level up and make your dragon stronger."
              : levelList?.[currentSelect]?.description}
          </ContentText>
        </div>
        <ListBox id={"scrollContainer"}>
          {levelList?.length > 0 &&
            levelList?.map((item: any) => {
              const { ageLevel, achieved } = item;
              const selected = currentSelect + 1 === ageLevel;
              if (!achieved) {
                return (
                  <DisablePet key={ageLevel}>
                    <img
                      className="yes_tick"
                      src={"assets/v2/ic_lock.png"}
                      alt="yes"
                      style={{ width: "28px" }}
                    />
                  </DisablePet>
                );
              }

              return (
                <AvatarBorder key={ageLevel}>
                  {selected && (
                    <img
                      className="yes_tick"
                      src={"assets/v2/ic_yes.png"}
                      alt="yes"
                      style={{ width: "20px" }}
                    />
                  )}
                  <AvatarIconButton
                    disableFocusRipple={true}
                    onClick={() => {
                      setCurrentSelect(ageLevel - 1);
                    }}
                  >
                    <img
                      // src={`assets/v2/petlevel/icon-pet-lv_${ageLevel}.png`}
                      src={levelList?.[currentSelect]?.avatarUrl}
                      alt="pet"
                      style={{ width: "34px" }}
                    />
                  </AvatarIconButton>
                </AvatarBorder>
              );
            })}
        </ListBox>
      </DescriptionBox>
    </MainContent>
  );
};

export default PetLevelDetail;
