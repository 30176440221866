// src/components/Explosion.js
import React, { useEffect } from "react";
import "./styles.css";

const Explosion = ({ position, top, onAnimationEnd, setExplosion }) => {
  const explosionStyle = {
    top: `${top}px`,
    left: `${position}px`,
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setExplosion(null);
      //   onAnimationEnd(); // Notify when the animation ends
    }, 400);

    return () => clearTimeout(timer);
  }, []);

  return <div className="explosion" style={explosionStyle}></div>;
};

export default Explosion;
