import { WalletAdapterNetwork, WalletError } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import { useCallback, useMemo } from "react";
import { SDKProvider, type SDKInitOptions} from "@tma.js/sdk-react";

function SolanaAdapterProvider({ children }: { children: any }) {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(
    () => [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
    [network]
  );

  const options: SDKInitOptions = {
    acceptCustomStyles: true,
    cssVars: true,
  };

  const onError = useCallback((error: WalletError) => {
    console.log("🚀 ~ Solana Adapter ~ error:", error);
  }, []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <SDKProvider options={options}>
        <WalletProvider wallets={wallets} onError={onError} autoConnect>
          {/* autoConnect */}
          {children}
          {/* <WalletModalProvider className="model-solana-custom"></WalletModalProvider> */}
        </WalletProvider>
      </SDKProvider>
    </ConnectionProvider>
  );
}

export default SolanaAdapterProvider;
