import { useDispatch } from "@/hooks/redux";
import * as authApi from "@/apis/auth";
import * as nftPetApi from "@/apis/nft-pet";
import { AuthContext } from "@/providers/AuthProvider";
import { appSelectors } from "@/redux/features/app/selectors";
import authSlice from "@/redux/features/auth/slice";
import { syncNewPetAsync } from "@/redux/features/pet/saga";
import { petSelectors } from "@/redux/features/pet/selectors";
import { ERROR_WALLET } from "@/utils/constants";
import { mintNFT } from "@/utils/mintNFT";
import { useWallet } from "@solana/wallet-adapter-react";
import { useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useTokens from "./useTokens";
import { initPet } from "@/apis/nft-pet";
import petSlice from "@/redux/features/pet/slice";
import { useNavigate } from "react-router-dom";
import { PetWithState } from "@/types/pet";

export default function useMintNft() {
  const [isMinting, setIsMinting] = useState(false);
  const wallet = useWallet();
  const dispatch = useDispatch();
  const global = useSelector(appSelectors.selectGlobal);
  const { provider, isSocialLogin, logout } = useContext(AuthContext);
  const pets = useSelector(petSelectors.selectPets);
  const { gemToken } = useTokens();
  const balance = useMemo(() => {
    return +gemToken.balance / 10 ** gemToken.decimals;
  }, [gemToken]);
  // const onMintNftOLD = useCallback(async () => {
  //   setIsMinting(true);
  //   let nftId = "123456";
  //   try {
  //     // if (balance < (global?.mintPrice || 1)) {
  //     //   toast.error("Your MGEM balance is insufficient. Deposit now.");
  //     //   return;
  //     // }
  //     // if (isSocialLogin) {
  //       // nftId = await mintNFT();
  //     // } else {
  //     //   nftId = await mintNFT(wallet);
  //     // }
  //     // if (pets.length < 1) {
  //     //   dispatch(authSlice.actions.setIsJustMint(true));
  //     // }
  //     // Get list my pet loop
  //     dispatch(syncNewPetAsync({ nftPublicKey: nftId }));
  //     toast.success("Mint NFT Successfully");
  //   } catch (error: any) {
  //     const message = error?.message;
  //     if (
  //       message === ERROR_WALLET.UninitializedWalletAdapterError ||
  //       message?.includes("The current wallet adapter is not initialized.")
  //     ) {
  //       logout();
  //       return;
  //     }
  //     toast.error(message);
  //   } finally {
  //     setIsMinting(false);
  //   }
  //   return nftId;
  // }, [balance, global?.mintPrice, isSocialLogin, dispatch, provider, wallet]);
  const onMintNft = async () => {
    setIsMinting(true);
    try {
      const petData = await initPet();
      console.log("My pet: ", petData);
      if (petData?.id) {
        const profile: any = await authApi.getProfile();
        const { pets } = profile;
        console.log("pet: ", pets);
        petSlice.actions.setPets({
          pets,
        });
        if (pets.length > 0) {
          const nftId = pets[0].nftId;
          const pet: PetWithState = await nftPetApi.getMyPetFeed(nftId);
          dispatch(petSlice.actions.activePet({ pet }));
        }
      }
    } catch (error: any) {
      const message = error?.message;
      toast.error(message);
    } finally {
      setIsMinting(false);
    }
  };
  return { onMintNft, isMinting };
}
