import { axiosPrivate } from "@/http/axiosPrivate";
import {
  GetMyPetFeedResponse,
  GetMyPetResponse,
  GetMyPetsResponse,
  ShoppingResponse,
  ShoppingSellResponse,
  ShoppingSetAsMainResponse,
} from "@/types/apis/nft-pet";
import { GlobalFeedItem } from "@/types/app";
import { Order } from "@/types/order";
import { Pet, PetItem, PetWithState } from "@/types/pet";

export const getMyPets = async (): Promise<Pet[]> => {
  const res = await axiosPrivate.get<GetMyPetsResponse>(`/pet/mypets`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  const { data = [] } = res.data;

  return data;
};

export const getMyPetBurn = async (): Promise<Pet[]> => {
  const res = await axiosPrivate.post<GetMyPetsResponse>(`/mypet/burn`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  const { data = [] } = res.data;

  return data;
};

export const claimReward = async (nftId: any, points: any): Promise<{}> => {
  const data = {
    nftId,
    points,
  };

  const res: any = await axiosPrivate.post<ShoppingResponse>(
    `game/claim`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (res.status !== 200) {
    throw new Error(res.message);
  }

  if (res.data.status !== 1) {
    throw new Error(res.data.message);
  }

  return { order: res.data };
};

export const getMyPet = async (nftId: string): Promise<PetWithState> => {
  const res = await axiosPrivate.get<GetMyPetResponse>(`/pet/mypets/${nftId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  const { pet, petImage, petItems, petLevel, poo } = res.data;

  const data = {
    pet,
    petImage,
    petItems,
    petLevel,
    poo,
  };

  return data;
};

export const getMyPetFeed = async (nftId: string): Promise<PetWithState> => {
  const res = await axiosPrivate.get<any>(`/pet/mypets/${nftId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  const {
    pet,
    petImage,
    petItems,
    petLevel,
    petEquip,
    petState,
    poo,
    petStatus,
  } = res.data;

  const data = {
    pet,
    petImage,
    petItems,
    petLevel,
    poo,
    petEquip,
    petState,
    petStatus,
  };

  return data;
};

export const givePetItem = async (
  nftId: string,
  payload: any
): Promise<any> => {
  const data = payload;
  const res = await axiosPrivate.post<GetMyPetFeedResponse>(
    `/pet/${nftId}/feed`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  return res?.data;
};

export const updatePetName = async (
  id: any,
  nftPublicKey: any,
  nftId: any,
  name: any
): Promise<{ message: string; rewardIndex: number }> => {
  const data = {
    NftPublicKey: nftPublicKey,
    NftId: nftId,
    Name: name,
  };

  const res = await axiosPrivate.post<any>(`pet/name/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  if (res.data.status !== 1) {
    throw new Error("bad request");
  }

  return res.data;
};

export const levelUpPet = async (
  nftPublicKey: any,
  nftId: any
): Promise<{ message: string; rewardIndex: number }> => {
  const data = {
    NftPublicKey: nftPublicKey,
    NftId: nftId,
  };

  const res = await axiosPrivate.post<any>(`pet/level-up/${nftId}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  if (res.data.status !== 1) {
    throw new Error("bad request");
  }

  return res.data;
};

export const shoppingNew = async (
  nftPublicKey: string,
  nftId: string,
  itemId: number
): Promise<{ order: Order }> => {
  const data = {
    nftPublicKey,
    nftId,
    itemId,
  };

  const res = await axiosPrivate.post<ShoppingResponse>(
    `pet/shopping/new`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  if (res.data.status !== 1) {
    throw new Error(res.data.message);
  }

  return { order: res.data.data };
};

export const shoppingUpdate = async (
  // itemTx: string,
  itemOrderId: string
): Promise<{ order: Order }> => {
  const data = {
    // itemTx,
    itemOrderId,
  };

  const res = await axiosPrivate.post<ShoppingResponse>(
    `pet/shopping/update`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  if (res.data.status !== 1) {
    throw new Error("bad request");
  }

  return { order: res.data.data };
};

export const shoppingSell = async (
  nftId: string,
  itemId: number
): Promise<{ item: GlobalFeedItem }> => {
  const data = {
    nftId,
    itemId,
  };

  const res = await axiosPrivate.post<ShoppingSellResponse>(
    `pet/shopping/sell`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  if (res.data.status !== 1) {
    throw new Error("bad request");
  }

  return { item: res.data.data };
};

export const shoppingSetAsMain = async (
  nftId: string,
  itemId: number
): Promise<{ item: PetItem }> => {
  const data = {
    nftId,
    itemId,
  };

  const res = await axiosPrivate.post<ShoppingSetAsMainResponse>(
    `pet/item/update`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  if (res.data.status !== 1) {
    throw new Error("bad request");
  }

  return { item: res.data.data };
};

export const initPet = async (): Promise<any> => {
  const res: any = await axiosPrivate.get<any>(`/pet/init`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res?.data;
};

export const sendGameResult = async (
  rewards: number
): Promise<{ item: PetItem }> => {
  const data = {
    rewards: rewards,
  };

  const res = await axiosPrivate.post<any>(`/game/play`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  if (res.data.status !== 1) {
    throw new Error(res?.data?.message);
  }

  return { item: res.data.data };
};

export const getAllLevelInfo = async (): Promise<any> => {
  const res: any = await axiosPrivate.get<any>(`/pet/mypets/levels`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res?.data?.data;
};

export const getAllTaskLists = async (): Promise<any> => {
  const res: any = await axiosPrivate.get<any>(`/pet/tasks`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res?.data?.data;
};

export const confirmLevelUp = async () => {
  const data = {
    confirm: true,
  };

  const res = await axiosPrivate.post<any>(`/pet/confirm-level-up`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  if (res.data.status !== 1) {
    throw new Error(res?.data?.message);
  }

  return { item: res.data.data };
};

export const callDoTaskAction = async (id: any) => {
  const data = {};

  const res = await axiosPrivate.post<any>(`/pet/task/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  if (res.data.status !== 1) {
    throw new Error(res?.data?.message);
  }

  return res?.data?.data;
};


//Friend Invite page
export const getAllFriends = async (): Promise<any> => {
  const res: any = await axiosPrivate.get<any>(`/user/friends`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res?.data?.data;
};

export const claimReferralPoint = async () => {
  const data = {};

  const res = await axiosPrivate.post<any>(`/user/claim-referral-points`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  if (res.data.status !== 1) {
    throw new Error(res?.data?.message);
  }

  return res?.data?.data;
};

export const redeemFriendCode = async (code: any) => {
  const data = {
    "inviteCode": code
  };

  const res = await axiosPrivate.post<any>(`/user/accept-invite-code`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  if (res.data.status !== 1) {
    throw new Error(res?.data?.message);
  }

  return res?.data?.data;
};
