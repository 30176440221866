import { axiosPrivate } from "@/http/axiosPrivate";

export const getShopItems = async (isFree: boolean) => {
  const data = {
    itemIsFree: isFree,
  };
  const res: any = await axiosPrivate.post<any>(`/shop/items`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res?.data;
};

export const getBuyItemInfo = async (payload: any) => {
  const data = payload;
  const res: any = await axiosPrivate.post<any>(`/shop/buy`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res?.data;
};
