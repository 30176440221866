import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

// project imports /Users/huytran/Documents/GitHub/maneko/public/assets/images2/mypet-shop.svg

import { ReactComponent as MenuHistoryIcon } from "@/assets/svg/menu-history.svg";
import { ReactComponent as MenuLeaderboardIcon } from "@/assets/svg/menu-leaderboard.svg";
import { ReactComponent as MenuMyPetIcon } from "@/assets/svg/menu-my-pet.svg";
import { ReactComponent as MenuWalletIcon } from "@/assets/svg/menu-wallet.svg";
import { ReactComponent as MenuShopIcon } from "@/assets/svg/menu-shop.svg";
import { ReactComponent as MenuTasksIcon } from "@/assets/svg/menu-task.svg";

import Loader from "@/components/Loader";
import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import React from "react";

// ==============================|| MAIN LAYOUT ||============================== //
const Main = styled("main")(({ theme }) => ({
  position: "relative",
  // backgroundColor: "#F7E8F6",
  background:
    "linear-gradient(0deg,rgba(0, 0, 0, 1), rgba(27, 48, 57, 0.5), rgba(27, 48, 57, 1))",
  flexGrow: 1,
  height: "100vh",
  overflow: "hidden",
  width: `100%`,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  paddingBottom: "90px",
  maxWidth: "500px",
}));

const Content = styled(Box)(({ theme }) => ({
  height: "100%",
  overflowY: "auto",
}));

const Navigation = styled(Box)(() => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  maxWidth: "500px",
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(() => ({
  "& .MuiBottomNavigationAction-label": {
    whiteSpace: "nowrap",
  },
}));

const MainLayout: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <Main>
      <ContentWrapper>
        <Content id="scrollableDiv">
          <React.Suspense fallback={<Loader />}>
            <Outlet />
          </React.Suspense>
        </Content>
      </ContentWrapper>
      <Navigation>
        <BottomNavigation
          showLabels
          value={pathname}
          onChange={(event, newValue) => {
            navigate(newValue);
            console.log(newValue);
          }}
          style={{
            backgroundColor: "#FFFFFF1A",
          }}
        >
          <StyledBottomNavigationAction
            label="My Pet"
            value={"/my-pet"}
            icon={<MenuMyPetIcon fill="currentColor" />}
          />
          <StyledBottomNavigationAction
            label="Leaders"
            value={"/leaderboard"}
            icon={<MenuLeaderboardIcon fill="currentColor" />}
          />
          <StyledBottomNavigationAction
            label="Tasks"
            value={"/tasks"}
            icon={<MenuTasksIcon fill="currentColor" />}
          />
          <StyledBottomNavigationAction
            label="Invite"
            value={"/refer-and-earn"}
            icon={<MenuShopIcon fill="currentColor" />}
          />
        </BottomNavigation>
      </Navigation>
    </Main>
  );
};

export default MainLayout;
