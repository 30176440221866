import { CircularProgress, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { callDoTaskAction } from "@/apis/nft-pet";

const TaskLoadingButton = styled(LoadingButton)(() => ({
  backgroundColor: "#02B1AA !important",
  borderRadius: "28px",
  color: "white",
  textTransform: "capitalize",
  fontSize: "14px",
  width: "70px",
  "&:hover": {
    opacity: "0,7",
  },

  "&.MuiLoadingButton-root": {
    backgroundColor: "#02B1AA !important",
  },
}));
function TaskButton({
  id = "",
  url = "",
  status = "pending",
  finishFunction = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const handleClickTask = async () => {
    localStorage.setItem("taskId", id);
    setTimeout(() => {
      window.location.href = url;
    }, 500);
  };
  document.addEventListener("visibilitychange", function () {
    if (document.hidden) {
      console.log(
        "User navigated away from the web app (likely back to Telegram)"
      );
      // Perform any action you need here
    } else {
      let storedId = localStorage.getItem('taskId') || null;
      if (id == storedId){
        handleCompleteTask()
        localStorage.removeItem('taskId');
      }
    }
  });

  useEffect(()=> {
    let storedId = localStorage.getItem('taskId') || null;
    if (id == storedId){
      handleCompleteTask()
      localStorage.removeItem('taskId');
    }
  }, [id])

  const handleCompleteTask = async () => {
    setLoading(true);
    const data = await callDoTaskAction(id);
    setTimeout(() => {
      setLoading(false);
      finishFunction();
      toast.success("Task completed successfully!");
    }, 2000);
  };


  return (
    <>
      {status === "pending" ? (
        <TaskLoadingButton
          loading={loading}
          loadingIndicator={<CircularProgress color="secondary" size={20} />}
          onClick={handleClickTask}
        >
          Start
        </TaskLoadingButton>
      ) : (
        <img
          src={"assets/v2/ic_done.png"}
          alt="level"
          style={{ width: "30px" }}
        />
      )}
    </>
  );
}

export default TaskButton;
