import { useContext, useEffect, useState } from "react";

// Plugins

// Adapters
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, CircularProgress, styled } from "@mui/material";
import {
  AuthContext,
  SocialLogin,
  WalletLogin,
} from "../../providers/AuthProvider";

import { useSelector } from "@/hooks/redux";
import { logoutAsync } from "@/redux/features/auth/saga";
import { authSelector } from "@/redux/features/auth/selectors";
import authSlice from "@/redux/features/auth/slice";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useDispatch } from "react-redux";
import "./LoginPage.css";
import { useNavigate } from "react-router-dom";
//Services

const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  // backgroundColor: "#F7E8F6",
  background:
    "linear-gradient(0deg,rgba(0, 0, 0, 1), rgba(27, 48, 57, 0.5), rgba(27, 48, 57, 1))",
}));

export const WalletAmountBox = styled(Button)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "left !important",
  background: "#fff",
  boxShadow: " 1px 1px 0px 0px #0D1615",
  borderRadius: "10px",
  border: "1px solid #0D1615",
  padding: "10px",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "15px",
  letterSpacing: "0.07px",
  alignItems: "center",
  textTransform: "none",
}));
export const WalletAmountContinue = styled(Button)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between !important",
  background: "#fff",
  boxShadow: " 1px 1px 0px 0px #0D1615",
  borderRadius: "10px",
  border: "1px solid #0D1615",
  padding: "10px",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "15px",
  letterSpacing: "0.07px",
  alignItems: "center",
  textTransform: "none",
}));

const Logo = styled("img")(() => ({
  // marginTop: "30%",
  // width: "188px",
  // height: "200px",
  width: "200px",
  marginBottom: "50px",
  imageRendering: "pixelated",
}));
const LogoModal = styled("img")(() => ({
  width: "98px",
}));

const LogoSocial = styled("img")(() => ({
  width: "24px",
  marginRight: "20px",
}));

const Content = styled(Box)(() => ({
  padding: "12px 50px 12px 50px",
  // marginTop: "-50px",
}));

const ContentTitle = styled(Box)(() => ({
  textAlign: "center",
  fontSize: "17px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "0.07px",
  color: "white",
  paddingBottom: "18px",
}));

const ContentDescription = styled(Box)(() => ({
  textAlign: "center",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",
  letterSpacing: "0.07px",
  color: "white",
  // marginTop: 25,
  justifyContent: "center",
}));

const SocialButtons = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const LoginButton = styled(Button)(() => ({
  marginTop: "48px",
  width: "150px",
}));

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const authState = useSelector(authSelector.selectorDomain);
  const { web3auth, login, loading, loginUserWithTon } =
    useContext(AuthContext);

  const logout = async () => {
    if (!web3auth) {
      return;
    }
    if (!web3auth.connected) {
      dispatch(authSlice.actions.setReferral(""));
      dispatch(logoutAsync());
      window.token = "";
      localStorage.removeItem("skipScreenInviteCode");
      return;
    }
    await web3auth.logout();
    dispatch(authSlice.actions.setReferral(""));
    dispatch(logoutAsync());
    window.token = "";
    localStorage.removeItem("skipScreenInviteCode");
  };

  const loggedInView = (
    <>
      <div className="flex-container">
        <Button variant="contained" onClick={logout} className="card">
          Log Out
        </Button>
      </div>
      <div id="console" style={{ whiteSpace: "pre-line" }}>
        <p style={{ whiteSpace: "pre-line" }}>Logged in Successfully!</p>
      </div>
    </>
  );

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      console.log('re direct');
      navigate("/my-pet");
    }, 2000);
  }, []);

  return (
    <Container>
      <Logo src="assets/images2/login-dragon.png" alt="Logo" />
      <Content>
        <ContentTitle>
          Play, nurture, battle – discover joy in ManekoPet.
        </ContentTitle>
        {/* <ContentDescription>
          Sign up with your email, we’ll create a Solana wallet for you.
        </ContentDescription> */}
      </Content>
      {/* <TonConnectButton /> */}
      {/* <SocialButtons>
        {authState?.user !== null && web3auth?.connected
          ? loggedInView
          : unloggedInView}
      </SocialButtons> */}
      {/* {tonWallet && ( */}
      <LoginButton variant="contained" onClick={() => loginUserWithTon(true)}>
        Start TEST
      </LoginButton>
      {/* )} */}
    </Container>
  );
};

export default LoginPage;
