import * as authApi from "@/apis/auth";
import { ENVS } from "@/configs/Configs.env";
import { useSelector } from "@/hooks/redux";
import useAccounts from "@/hooks/useAccounts";
import useMintNft from "@/hooks/useMintNft";
import { logoutAsync } from "@/redux/features/auth/saga";
import { authSelector } from "@/redux/features/auth/selectors";
import authSlice from "@/redux/features/auth/slice";
import { PRIVATE_KEY_B58 } from "@/utils/constants";
import { getSignature } from "@/utils/solana";
import SolanaRpc from "@/utils/solanaRPC";
import { mplCandyMachine } from "@metaplex-foundation/mpl-candy-machine";
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  PhantomWalletName,
  SolflareWalletName,
} from "@solana/wallet-adapter-wallets";
import {
  ADAPTER_EVENTS,
  CHAIN_NAMESPACES,
  CONNECTED_EVENT_DATA,
  IProvider,
  OPENLOGIN_NETWORK_TYPE,
  UserInfo,
  WALLET_ADAPTERS,
} from "@web3auth/base";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { PhantomAdapter } from "@web3auth/phantom-adapter";
import {
  SolanaPrivateKeyProvider,
  SolanaWallet,
} from "@web3auth/solana-provider";
import { SolflareAdapter } from "@web3auth/solflare-adapter";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fakeMyprofile, fakeToken } from "./fakeAssets";
// import { useTonAddress } from "@tonconnect/ui-react";
import { useLaunchParams } from "@tma.js/sdk-react";

export const umi = createUmi(ENVS.REACT_APP_SOL_RPC, {
  commitment: "confirmed",
}).use(mplCandyMachine());

const WEB3AUTH_CLIENT_ID = ENVS.REACT_APP_WEB3_AUTH_CLIENT_ID;
// const WEB3AUTH_CLIENT_ID =
//   "BEglQSgt4cUWcj6SKRdu5QkOXTsePmMcusG5EAoyjyOYKlVRjIF1iCNnMOTfpzCiunHRrMui8TIwQPXdkQ8Yxuk";

export enum SocialLogin {
  GOOGLE = "google",
  TWITTER = "twitter",
  APPLE = "apple",
}
export enum WalletLogin {
  PHANTOM = "phantom",
  SOLFLARE = "solflare",
}
export interface AuthProviderData {
  web3auth: Web3AuthNoModal | null;
  provider: IProvider | null;
  user: Partial<UserInfo> | null;
  // onSuccessfulLogin: (data: CONNECTED_EVENT_DATA, user: any) => void;
  login: (props: { tSocial?: SocialLogin; tWallet?: WalletLogin }) => void;
  loginUserWithTon: any;
  logout: () => void;
  address: string;
  isSocialLogin: boolean;
  loading: {
    google: boolean;
    twitter: boolean;
    apple: boolean;
    solflare: boolean;
    phantom: boolean;
  };
}

export const AuthContext = React.createContext<AuthProviderData>({
  web3auth: null,
  provider: null,
  user: null,
  // onSuccessfulLogin: (data: any) => {},
  login: () => {},
  loginUserWithTon: () => {},
  logout: () => {},
  address: "",
  isSocialLogin: false,
  loading: {
    google: false,
    twitter: false,
    apple: false,
    solflare: false,
    phantom: false,
  },
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { onMintNft, isMinting } = useMintNft();
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [user, setUser] = useState<Partial<UserInfo> | null>(null);
  const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);
  const [loading, setLoading] = useState({
    google: false,
    twitter: false,
    apple: false,
    solflare: false,
    phantom: false,
  });
  const dispatch = useDispatch();
  // const tonAddress = useTonAddress();
  const [address, setAddress] = useState<string>("");
  const launchParams = useLaunchParams();
  // const { address = "" } = useAccounts();
  const wallet = useWallet();
  const authState = useSelector(authSelector.selectorDomain);
  // const navigate = useNavigate();

  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.SOLANA,
    chainId: ENVS.REACT_APP_WEB3_AUTH_CHAIN_ID, // Please use 0x1 for Mainnet, 0x2 for Testnet, 0x3 for Devnet
    rpcTarget: "https://api.devnet.solana.com",
    displayName: ENVS.REACT_APP_WEB3_AUTH_NETWORK,
    blockExplorer: "https://explorer.solana.com",
    ticker: "SOL",
    tickerName: "Solana Token",
    // chainId: "0x1", // Please use 0x1 for Mainnet, 0x2 for Testnet, 0x3 for Devnet
    // rpcTarget: ENVS.REACT_APP_SOL_RPC, // This is the public RPC we have added, please pass on your own endpoint while creating an app
  };
  // createUmi(rpcURL).use(mplCandyMachine()).use(walletAdapterIdentity(wallet));
  const loginUserWithTon = async () => {
    console.log("Called login with ton!")
    const user = launchParams?.initData?.user;

    const payload = {
      init_data_raw: launchParams?.initDataRaw,
      first_name: user?.firstName,
      last_name: user?.lastName,
      telegram_id: user?.id.toString(),
    };

    const { token } = await authApi.login(payload);

    if (token) {
      // console.log("set access token", token);
      localStorage.setItem("token", token);
      // if (authState?.referral && !user?.invitedBy) {
      //   await authApi.updateReferralProfile(
      //     publicAddress,
      //     authState.referral
      //   );
      // }
      const profile: any = await authApi.getProfile();
      console.log("my profile: ", profile);
      dispatch(
        authSlice.actions.loginSuccess({
          accessToken: token,
          user: profile?.user,
          signed: 1,
          balance: profile?.balance,
        })
      );

      if (!profile?.hasPet) {
        await onMintNft();
      }
    }

    setAddress('tonAddress');
  };

  // USE TO RUN TEST ONLY - HUY

  // const loginUserWithTon = async () => {
  //   const tonConnect: boolean = true;
  //   if (tonConnect) {
  //     const token = fakeToken;
  //     const profile = fakeMyprofile;
  //     const signed = 1;
  //     dispatch(
  //       authSlice.actions.loginSuccess({
  //         accessToken: token,
  //         user: profile?.user,
  //         signed: signed,
  //         balance: profile?.balance,
  //       })
  //     );
  //   }
  //   setAddress(tonAddress);
  // };

  useEffect(() => {
    const init = async () => {
      try {
        const web3authClient = new Web3AuthNoModal({
          clientId: WEB3AUTH_CLIENT_ID,
          chainConfig: chainConfig,
          sessionTime: 7 * 24 * 3600,
          // uiConfig refers to the whitelabeling options, which is available only on Growth Plan and above
          // Please remove this parameter if you're on the Base Plan
          // uiConfig: {
          //   appName: "Maneko Game",
          //   mode: "dark",
          //   loginMethodsOrder: ["google", "apple", "twitter"],
          //   logoLight: "https://web3auth.io/images/web3auth-logo.svg",
          //   logoDark: "https://web3auth.io/images/web3auth-logo---Dark.svg",
          //   defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
          //   loginGridCol: 3,
          //   primaryButton: "externalLogin", // "externalLogin" | "socialLogin" | "emailLogin"
          // },
          web3AuthNetwork:
            ENVS.REACT_APP_WEB3_AUTH_NETWORK as OPENLOGIN_NETWORK_TYPE,
        });
        const privateKeyProvider = new SolanaPrivateKeyProvider({
          config: { chainConfig },
        });
        const openloginAdapter = new OpenloginAdapter({
          privateKeyProvider,
          // loginSettings: {
          //   mfaLevel: "none",
          // },
          adapterSettings: {
            uxMode: "redirect", // "redirect" | "popup"
            whiteLabel: {
              appName: "Maneko Game",
              logoLight: "https://web3auth.io/images/web3auth-logo.svg",
              logoDark: "https://web3auth.io/images/web3auth-logo---Dark.svg",
              defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
            },
            // mfaSettings: {
            //   deviceShareFactor: {
            //     enable: true,
            //     priority: 1,
            //     mandatory: true,
            //   },
            //   backUpShareFactor: {
            //     enable: true,
            //     priority: 2,
            //     mandatory: false,
            //   },
            //   socialBackupFactor: {
            //     enable: true,
            //     priority: 3,
            //     mandatory: false,
            //   },
            //   passwordFactor: {
            //     enable: true,
            //     priority: 4,
            //     mandatory: false,
            //   },
            // },
          },
        });

        web3authClient.configureAdapter(openloginAdapter);

        const solflareAdapter = new SolflareAdapter({
          clientId: WEB3AUTH_CLIENT_ID,
        });
        web3authClient.configureAdapter(solflareAdapter);

        const phantomAdapter = new PhantomAdapter({
          clientId: WEB3AUTH_CLIENT_ID,
        });
        web3authClient.configureAdapter(phantomAdapter);

        setWeb3auth(web3authClient);
        // wallet.select(PhantomAdapter);
        // await web3authClient.initModal();
        await web3authClient.init();
        setProvider(web3authClient.provider);
      } catch (error) {
        console.log("🚀 ~ file: AuthProvider.tsx:84 ~ init ~ error:", error);
        dispatch(logoutAsync());
      }
    };

    init();
  }, []);
  // Huy Helps: logins
  const login = () => {};
  // const login = async ({
  //   tSocial,
  //   tWallet,
  // }: {
  //   tSocial?: SocialLogin;
  //   tWallet?: WalletLogin;
  // }) => {
  //   try {
  //     // if (!tSocial && !tWallet) return;
  //     if (!web3auth) return;
  //     let providerWeb3: any = null;
  //     await web3auth.init();
  //     if (!web3auth.connected) {
  //       console.log("web3auth.connected: ", web3auth.connected);
  //       // Login with social provider
  //       if (tSocial) {
  //         setLoading({
  //           google: tSocial === SocialLogin.GOOGLE,
  //           twitter: tSocial === SocialLogin.TWITTER,
  //           apple: tSocial === SocialLogin.APPLE,
  //           solflare: false,
  //           phantom: false,
  //         });

  //         providerWeb3 = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
  //           loginProvider: tSocial, // facebook, apple,
  //         });
  //         // should be end here...
  //       } else {
  //         setLoading({
  //           google: false,
  //           twitter: false,
  //           apple: false,
  //           solflare: tWallet === WalletLogin.SOLFLARE,
  //           phantom: tWallet === WalletLogin.PHANTOM,
  //         });
  //         providerWeb3 = await web3auth.connectTo(
  //           tWallet === WalletLogin.SOLFLARE
  //             ? WALLET_ADAPTERS.SOLFLARE
  //             : WALLET_ADAPTERS.PHANTOM
  //         );
  //       }
  //     } else {
  //       providerWeb3 = web3auth.provider;
  //     }

  //     console.log("providerWeb3: ", providerWeb3);

  //     if (providerWeb3) {
  //       console.log("here: ");
  //       setProvider(providerWeb3);
  //       const solanaWallet = new SolanaWallet(providerWeb3);
  //       const address2 = await solanaWallet.requestAccounts();
  //       const infoUser = await web3auth.getUserInfo();

  //       const result = await authApi.requestNonce(address2[0]);
  //       const { nonce, publicAddress, signed } = result as any;
  //       const signature: string = await getSignature(
  //         publicAddress,
  //         nonce,
  //         providerWeb3
  //       );
  //       const { token } = await authApi.login(
  //         publicAddress,
  //         signature,
  //         nonce,
  //         infoUser || {}
  //       );
  //       setUser(infoUser);
  //       if (token) {
  //         window.token = token;
  //         // if (authState?.referral && !user?.invitedBy) {
  //         //   await authApi.updateReferralProfile(
  //         //     publicAddress,
  //         //     authState.referral
  //         //   );
  //         // }
  //         const profile: any = await authApi.getProfile();
  //         dispatch(
  //           authSlice.actions.loginSuccess({
  //             accessToken: token,
  //             user: profile?.user,
  //             signed: signed,
  //             balance: profile?.balance,
  //           })
  //         );
  //       } else {
  //         // TODO display error
  //       }
  //       // if (Object.keys(user).length === 0) {
  //       //   await wallet.connect();
  //       // }
  //     }
  //   } catch (error) {
  //     console.log("🚀 ~ file: AuthProvider.tsx:102 ~ login ~ error:", error);
  //     // throw error;
  //   } finally {
  //     setLoading({
  //       google: false,
  //       twitter: false,
  //       apple: false,
  //       solflare: false,
  //       phantom: false,
  //     });
  //   }
  // };

  const logout = useCallback(() => {
    if (!web3auth) return;
    wallet.disconnect();
    localStorage.removeItem(PRIVATE_KEY_B58);

    web3auth
      .logout()
      .then(() => {
        // login on logout
        dispatch(logoutAsync());
      })
      .catch((err: any) => {
        console.log("logout", err);
        dispatch(logoutAsync());
      });
  }, [dispatch, wallet, web3auth]);

  // const subscribeAuthEvents = (web3auth: Web3AuthNoModal) => {
  //   web3auth.on(
  //     ADAPTER_EVENTS.CONNECTED,
  //     async (data: CONNECTED_EVENT_DATA) => {
  //       if (data.adapter === "solflare") {
  //         wallet.select(SolflareWalletName);
  //         if (!wallet.connected) {
  //           // await wallet.connect();
  //         }
  //         console.log("🚀 ~ SolflareWalletName:", SolflareWalletName);
  //       }
  //       if (data.adapter === "phantom") {
  //         wallet.select(PhantomWalletName);
  //         console.log("🚀 ~ PhantomWalletName:", PhantomWalletName);
  //       }
  //       console.log("Yeah!, you are successfully logged in", data);
  //       try {
  //         const user = await web3auth.getUserInfo();
  //         // .then((user: Partial<OpenloginUserInfo>) => {
  //         onSuccessfulLogin(data, user);
  //         // });
  //       } catch (error) {
  //         console.log(
  //           "🚀 ~ file: AuthProvider.tsx:128 ~ web3auth.on ~ error:",
  //           error
  //         );
  //       }
  //     }
  //   );

  //   web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
  //     console.log("connecting");
  //   });
  //   web3auth.on(ADAPTER_EVENTS.CONNECTED, () => {
  //     console.log("connected");
  //     // subscribeAuthEvents(web3auth);
  //   });

  //   web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
  //     console.log("disconnected");
  //     setUser(null);
  //     setProvider(null);
  //     dispatch(logoutAsync());
  //   });

  //   // web3auth.on(ADAPTER_EVENTS.ERRORED, (error: any) => {
  //   //   console.log('some error or user have cancelled login request', error);
  //   // });

  //   // web3auth.on(LOGIN_MODAL_EVENTS.MODAL_VISIBILITY, (isVisible: any) => {
  //   //   console.log('modal visibility', isVisible);
  //   // });
  // };

  // useEffect(() => {
  //   if (!web3auth) return;
  //   subscribeAuthEvents(web3auth);

  //   // web3auth.init().catch((err: string) => {
  //   //   // wallet.connect();
  //   //   console.log("web3auth.initModal error" + err);
  //   // });
  // }, [web3auth]);

  // useEffect(() => {
  //   wallet.select(PhantomWalletName);
  // }, []);

  useEffect(() => {
    (async () => {
      try {
        if (provider && address) {
          const solana = new SolanaRpc(provider);
          const privateKey = await solana.getPrivateKey();
          if (privateKey) {
            localStorage.setItem(PRIVATE_KEY_B58, privateKey);
          }
        }
        //  else {
        //   localStorage.removeItem(PRIVATE_KEY_B58);
        // }
      } catch (error) {
        console.log("🚀 ~ error:", error);
      }
    })();
  }, [provider, address]);
  const ctx: AuthProviderData = {
    web3auth,
    provider,
    user,
    login,
    logout,
    address,
    loading,
    isSocialLogin: user ? Object.keys(user).length !== 0 : false,
    loginUserWithTon,
  };
  return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>;
};

// export const AuthConsumer = AuthContext.Consumer;
