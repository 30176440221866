import zIndex from "@mui/material/styles/zIndex";
import React from "react";
const Basket = ({ position }) => {
  const petImage = localStorage.getItem('petImage');
  const basketStyle = {
    position: "absolute",
    bottom: "170px",
    left: `${position}px`,
    width: "100px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    userSelect: "none",

    // alignItem: "center",
  };
  

  const dragonStyle = {
    height: "100px",
    userSelect: "none",
  };

  return (
    <div
      style={basketStyle}
      draggable="false" // Disable dragging of the element
      onDragStart={(e) => e.preventDefault()}
    >
      <img
        draggable="false" // Disable dragging of the element
        onDragStart={(e) => e.preventDefault()}
        style={dragonStyle}
        className="dragon_logo"
        src={petImage}
        alt="Logo"
      />
    </div>
  );
};

export default Basket;
