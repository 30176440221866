export enum PetStatusLive {
  Live0 = 0,
  Live1 = 1,
  Snap = 2,
  Faint = 3,
  Death = 4,
}

export enum PetState {
  IDLE = 1,
  DEAD = 2,
  EATING = 3,
  EVIL = 4,
  SAD = 7,
  SLEEP = 8,
}

export enum PetEquip {
  BITCOIN = 2003,
  GLASSES = 2005,
  HAMMER = 2002,
  PILLOW = 2004,
  SUPERMAN = 2007,
  WIG = 2006,
}

export const PetStateData: Record<
  PetState,
  {
    image: string;
    count: number;
    equips?: Record<PetEquip, { image: string; count: number }>;
  }
> = {
  [PetState.IDLE]: {
    image: "assets/animated/pet-stage/idle/out.png",
    count: 8,
    equips: {
      [PetEquip.BITCOIN]: {
        image: "assets/animated/pet-stage/idle/bitcoin/out.png",
        count: 8,
      },
      [PetEquip.GLASSES]: {
        image: "assets/animated/pet-stage/idle/glasses/out.png",
        count: 8,
      },
      [PetEquip.HAMMER]: {
        image: "assets/animated/pet-stage/idle/hammer/out.png",
        count: 8,
      },
      [PetEquip.PILLOW]: {
        image: "assets/animated/pet-stage/idle/pillow/out.png",
        count: 8,
      },
      [PetEquip.SUPERMAN]: {
        image: "assets/animated/pet-stage/idle/superman/out.png",
        count: 8,
      },
      [PetEquip.WIG]: {
        image: "assets/animated/pet-stage/idle/wig/out.png",
        count: 8,
      },
    },
  },
  [PetState.DEAD]: {
    image: "assets/animated/pet-stage/dead/out.png",
    count: 17,
  },
  [PetState.EATING]: {
    image: "assets/animated/pet-stage/eating/out.png",
    count: 8,
  },
  [PetState.EVIL]: {
    image: "assets/animated/pet-stage/evil/out.png",
    count: 9,
  },
  [PetState.SAD]: {
    image: "assets/animated/pet-stage/sad/out.png",
    count: 9,
  },
  [PetState.SLEEP]: {
    image: "assets/animated/pet-stage/sleep/out.png",
    count: 8,
  },
};

export interface Pet {
  id: number;
  nftId: string;
  nftPublicKey: string;
  name: string;
  image: string;
  petLevel: number;
  petScore: number;
  statusLive: number;
  timePetBorn: string;
  lastAttackUsed?: any;
  lastAttacked?: any;
  stars: number;
  timeUntilStarving?: any;
  shieldExpiredIn: string;
  petRewardsBalance: number;
  currentTickets: any;
  isLevelUp: boolean;
}

export interface PetLevel {
  AgeLevel: number;
  PointLevel: number;
  AgeName: string;
  Image: string;
}

export interface PetItem {
  nftId: string;
  itemId: number;
  itemAsMain: number;
  balance: number;
}

export interface PetWithState {
  pet: Pet;

  petImage: string;
  petItems: PetItem[];
  petLevel: PetLevel;
  petEquip?: number;
  petState?: number;
  poo: number;
}

export interface PetStateRedux {
  pets: Pet[];
  activePet: PetWithState | null;
}
