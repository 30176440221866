import * as gameApi from "@/apis/game";
import * as nftPetApi from "@/apis/nft-pet";
import { ReactComponent as AttackIcon } from "@/assets/svg/leaderboard-action-attack.svg";
import { ReactComponent as DefenseIcon } from "@/assets/svg/shield.svg";
import { useDispatch, useSelector } from "@/hooks/redux";
import useAudios from "@/hooks/useAudios";
import appSlice from "@/redux/features/app/slice";
import { petSelectors } from "@/redux/features/pet/selectors";
import petSlice from "@/redux/features/pet/slice";
import { getErrorMessage } from "@/types/error";
import { Pet, PetWithState } from "@/types/pet";
import useAnalyticsEventTracker from "@/utils/useAnalyticsEventTracker";
import * as amplitude from "@amplitude/analytics-browser";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  AppBar,
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Grid,
  Pagination,
  PaginationRenderItemParams,
  Toolbar,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import HeaderWallet from "../../components/HeaderWallet";
import FlexGrow from "../../components/presentation/FlexGrow";
import zIndex from "@mui/material/styles/zIndex";
import TaskButton from "./TaskButton";

export const MainContent = styled(Box)(() => ({
  // background: `linear-gradient(to right, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 50%), url(assets/v2/bg.png))`,
  backgroundImage: `url(assets/v2/bg_sm.png)` /* Set your background image URL */,
  // backgroundSize: "cover" /* Make the background image cover the entire div */,
  // backgroundPosition: "center" /* Center the background image */,
  backgroundSize: "100% 50%",
  height: "100%",
  /* Position the background image at the top */
  backgroundPosition: "top center",
  backgroundRepeat: "no-repeat",
  backgroundColor:
    "linear-gradient(180deg, rgba(27, 48, 57, 0.00) 0%, #1B3039 100%)",
  padding: "0px 0px 15px",
}));

const LeaderboardBox = styled(Box)(() => ({
  display: "flex",
  background: "#111D23",
  borderRadius: "10px",
  justifyContent: "center",
  alignItems: "center",
  borderBottom: "1px solid rgba(255, 255, 255, 0.05)",
  // boxShadow: "1px 1px 0px 0px rgba(13, 22, 21, 1)",
  padding: "16px",
}));

const ClaimBox = styled(Box)(() => ({
  position: "relative",
  // backgroundImage: `url(assets/v2/bg_sm.png)` /* Set your background image URL */,
  // backgroundSize: "cover" /* Make the background image cover the entire div */,
  // backgroundPosition: "center" /* Center the background image */,
  // backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "8px",
  marginBottom: "12px",
}));

const TaskLoadingButton = styled(Button)(() => ({
  backgroundColor: "#02B1AA",
  borderRadius: "28px",
  color: "white",
  textTransform: "capitalize",

  "&:hover": {
    opacity: "0,7",
  },
}));

const ClaimMainBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#111D23",
  backgroundImage: `url(assets/v2/bg_sm.png)` /* Set your background image URL */,
  backgroundSize: "cover" /* Make the background image cover the entire div */,
  backgroundPosition: "center" /* Center the background image */,
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100%",
  borderRadius: "12px",
  filter: "blur(2px)" /* Adjust the blur intensity */,
}));

const InviteButton = styled(Button)(() => ({
  color: "#000",

  "&:hover": {
    backgroundColor: "#EEE67B",
    opacity: "0.7",
    color: "#000",
  },
}));

const LeaderboardRank = styled(Box)(() => ({
  marginRight: "12px",
}));

const LeaderboardProfile = styled(Box)(() => ({
  flexGrow: "1",
}));
const FriendsPoints = styled(Box)(() => ({
  background: "linear-gradient(180deg, #02B1AA 0%, #EEE67B 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  color: "transparent",
}));
const LeaderboardProfileName = styled(Box)(() => ({
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "18px",
  letterSpacing: "0.07px",
  textAlign: "left",
  marginBottom: "4px",
  color: "white",
}));
const LeaderboardProfilePoints = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: "300",
  lineHeight: "15px",
  letterSpacing: "0.07px",
  textAlign: "left",
  color: "#7D97A2",
}));

const TransitionBox = styled(Box)(() => ({
  padding: "12px 0px",
  width: "100%",
  bottom: "0px",
  fontSize: "12px",
  fontWeight: "300",
  lineHeight: "15px",
  letterSpacing: "0.07px",
  textAlign: "center",
  "& div": {
    textAlign: "center",
    color: "#b0bcc1",
  },
}));

const StyledPagination = styled(Pagination)(() => ({
  padding: "0px 12px",
  "& ul": {
    justifyContent: "center",
  },
}));

const TotalFriendBox = styled(Box)(() => ({
  padding: "6px 12px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ContentTitle = styled(Box)(() => ({
  textAlign: "center",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "0.07px",
  // paddingBottom: "18px",
  fontSize: 30,
  fontWeight: "bold",
  textTransform: "uppercase",
  color: "#FFFFFF",
  WebkitTextStroke: "1.5px black",
}));

const ContentBody = styled(Box)(() => ({
  paddingTop: "18px",
  fontSize: 14,
  fontWeight: "400",
  color: "#FDFF7A",
  textAlign: "center",
}));

const ClaimMainContainer = styled(Box)(() => ({
  position: "relative",
  width: "80%",
  height: "110px",
  background: "linear-gradient(to right, #06c9c3 70%, #02B1AA, #EEE67B)",
  padding: "2px",
  borderRadius: "12px",

  "& .total_point": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "6px",
  },

  "& .score": {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    color: "white",
    fontSize: "24px",
    fontWeight: "400",
  },
}));

const ClaimButton = styled(Button)(() => ({
  border: "none",
  borderRadius: "40px",
  background: "#02B1AA",
  color: "#FFFFFF",
  width: "130px",
  height: "36px",
  fontSize: "12px",
  textTransform: "capitalize",

  "&:hover": {
    opacity: "0.8",
  },
}));

interface PagingButtonProps extends ButtonProps {
  selected?: boolean;
}

const PagingButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "selected",
})<PagingButtonProps>(({ selected }) => ({
  minWidth: "35px",
  height: "35px",
  padding: 0,
  background: selected ? "#02B1AA" : "#FFFFFF1A",

  "&:hover": {
    background: selected ? "#12eae3" : "#b0b0b01a",
    boxShadow: "1px 1px 0px 0px #0D1615",
  },
  "&:active": {
    background: selected ? "#02B1AA" : "#FFFFFF1A",
    boxShadow: "1px 1px 0px 0px #0D1615",
  },
}));

const Page: React.FC = () => {
  const [limit, setLimit] = useState<number>(5);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [taskList, setTaskList] = useState<any>([]);
  const [fetching, setFetching] = useState(false);
  const pets = useSelector(petSelectors.selectPets);
  const [firstLoading, setFirstLoading] = useState(pets.length > 0);
  const activePet = useSelector<PetWithState | null>(
    petSelectors.selectActivePet
  );
  const [isAttacking, setIsAttacking] = useState<boolean>(false);
  const { attackAudio } = useAudios();
  const dispatch = useDispatch();

  const gaEventTracker = useAnalyticsEventTracker("Leaderboard");

  const fetchActivePet = async (nftId: string) => {
    // Get pet active
    const pet: PetWithState = await nftPetApi.getMyPetFeed(nftId);
    dispatch(petSlice.actions.activePet({ pet }));
  };

  const fetchPets = async () => {
    try {
      // Get list my pet
      const pets: Pet[] = await nftPetApi.getMyPets();
      if (pets.length > 0) {
        dispatch(petSlice.actions.setPets({ pets }));

        // Get pet active
        fetchActivePet(activePet ? activePet.pet?.nftId : pets[0].nftId);
      }
    } catch (e: any) {
      console.log("Not found NFTs.");
    } finally {
    }
  };

  // useEffect(() => {
  //   const getLeaderboards = async () => {
  //     setFetching(true);
  //     try {
  //       const { data, total } = await gameApi.leaderboardGetList(
  //         (page - 1) * limit,
  //         limit
  //       );
  //       setLeaderboards(data);
  //       setTotal(total);
  //       setFetching(false);
  //     } catch (e: any) {
  //       setFetching(false);
  //     }
  //   };

  //   getLeaderboards();
  // }, [limit, page]);
  const getAllTaskList = async () => {
    setFetching(true);
    try {
      const data = await nftPetApi.getAllTaskLists();
      console.log("task data : ", data);
      setTaskList(data);
      // setTotal(total);
    } catch (e: any) {
      console.log("error: ", e);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getAllTaskList();
    })();
  }, []);

  useEffect(() => {
    gaEventTracker("view_leaderboard", "view_leaderboard");
    amplitude.track("view_leaderboard");
  }, []);

  useEffect(() => {
    if (!firstLoading || pets.length === 0) {
      fetchPets();
    }
  }, [firstLoading]);

  const renderPaginationItem = ({
    type,
    page,
    selected,
    onClick,
  }: PaginationRenderItemParams) => {
    if (type === "next") {
      return (
        <PagingButton onClick={onClick} selected={selected}>
          <ArrowRightIcon />
        </PagingButton>
      );
    } else if (type === "previous") {
      return (
        <PagingButton onClick={onClick} selected={selected}>
          <ArrowLeftIcon />
        </PagingButton>
      );
    } else if (type === "start-ellipsis" || type === "end-ellipsis") {
      return <PagingButton>...</PagingButton>;
    }
    return (
      <PagingButton onClick={onClick} selected={selected}>
        {page}
      </PagingButton>
    );
  };

  const handlePaginationChange = (
    evt: React.ChangeEvent<unknown>,
    page: number
  ) => {
    evt.preventDefault();
    if (page <= total && page > 0) {
      setPage(page);
    }
  };

  const getRankBackground = (rank: number): string => {
    // if (rank === 1) {
    //   return "#FEFAD3";
    // }
    // if (rank === 2) {
    //   return "#D3FEE7";
    // }
    // if (rank === 3) {
    //   return "#D3E4FE";
    // }
    return "#111D23";
  };

  return (
    <MainContent>
      <ClaimBox>
        <ContentBody>INSANE SKILLS</ContentBody>
        <ContentTitle>Tasks ({taskList?.length || 0})</ContentTitle>
        <TransitionBox>
          <div>We'll reward you immediately with points</div>
          <div>after each task completion.</div>
        </TransitionBox>
      </ClaimBox>
      <Grid container spacing={1} sx={{ mb: "20px", padding: "0px 12px" }}>
        {fetching && taskList?.length < 1 && (
          <div
            className="loading-process"
            style={{ width: "100%", textAlign: "center" }}
          >
            <CircularProgress className="" />
          </div>
        )}
        {taskList.map((l: any, idx: any) => {
          const rank = idx + 1 + limit * (page - 1);
          return (
            <Grid
              key={`l-${l.id}-${idx}`}
              item
              xs={12}
              sx={{ paddingLeft: "0px" }}
            >
              <LeaderboardBox>
                <LeaderboardRank>
                  <Button
                    size="small"
                    sx={{ background: getRankBackground(rank) }}
                    style={{
                      color: rank < 4 ? "black" : "white",
                      padding: "6px",
                    }}
                  >
                    <img
                      src={"assets/v2/ic_X.png"}
                      alt="level"
                      style={{ width: "24px" }}
                    />
                  </Button>
                </LeaderboardRank>
                <LeaderboardProfile>
                  <LeaderboardProfileName>{l.name}</LeaderboardProfileName>
                  <LeaderboardProfilePoints>
                    {/* + 1,000 / +1 TIK */}+{l.reward} RP{" "}
                    {l?.ticket && l?.ticket > 0 ? `/ +${l.ticket} TIK` : ""}
                  </LeaderboardProfilePoints>
                </LeaderboardProfile>
                <TaskButton
                  id={l.id}
                  url={l.url}
                  status={l.status}
                  finishFunction={getAllTaskList}
                />
                {/* <Box>{renderActionButton(l)}</Box> */}
              </LeaderboardBox>
            </Grid>
          );
        })}
      </Grid>
      {/* {total > limit && (
        <StyledPagination
          count={Math.ceil(total / limit)}
          page={page}
          onChange={handlePaginationChange}
          renderItem={renderPaginationItem}
          variant="outlined"
          shape="rounded"
        />
      )} */}
    </MainContent>
  );
};

export default Page;
